body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6fa;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  margin: 0;
  border: 1px solid #e3eaef;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

#agent-player wave {
  overflow: hidden !important;
}

#customer-player wave {
  overflow: hidden !important;
}

#both-player wave {
  overflow: hidden !important;
}

::-webkit-scrollbar-track {
  border-radius: 30px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #626262;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #626262 transparent;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}
